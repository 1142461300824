/* eslint-disable @next/next/no-img-element */
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Gallery.module.scss";
import Image from "next/image";
import { CircleButton } from "../../Buttons";
import cl from "classnames";
import { P1 } from "../../Typography";
import { useWindowSize } from "@/lib/hooks/useWindowSize";
import cn from "classnames";
import { Photo } from "@/lib/types/gallery";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaCarouselType } from "embla-carousel";
import { ArrowIcon } from "@/lib/ui/icons";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

export interface IGallerySlider {
  items: string[] | Photo[];
  theme?: "default" | "light" | "transparent";
  className?: string;
  fullSize?: boolean;
  onClick?: (index: number) => void;
  lastSlideContent?: ReactNode;
  initialSlide?: number;
  borderlessArrowButtonsMobile?: boolean;
  isNotCycle? :boolean;
}

export const GallerySlider: FC<IGallerySlider> = ({
  items,
  theme = "default",
  className,
  fullSize,
  onClick,
  lastSlideContent,
  initialSlide = 0,
  borderlessArrowButtonsMobile,
  isNotCycle,
}) => {
  const { isMobile } = useWindowSize(995);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isEnoughWidth, setIsEnoughWidth] = useState(true);
  const [isImageZoomed, setIsImageZoomed] = useState(false);
  const [drag, setDrag] = useState(true);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "center",
    startIndex: initialSlide,
    breakpoints: {
      4200: {
        align: "center",
      },
    },
  });

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [augmentedItems, setAugmentedItems] = useState<(string | Photo)[]>(items);

  const changeSlide = useCallback(() => {
    if (!emblaApi) return;
    const slideNum = emblaApi.selectedScrollSnap();
    setCurrentSlide(() => slideNum);
  }, [emblaApi]);

  const checkEnoughWidth = useCallback(
    (e: EmblaCarouselType) => {
      const canLoop = e.internalEngine().slideLooper.canLoop();

      if (items.length === 1) {
        return setIsEnoughWidth(false);
      }
      if (canLoop && items.length > 3) {
        setIsEnoughWidth(true);
      } else {
        isNotCycle ? setAugmentedItems(items) 
        : setAugmentedItems((oldItems) => [...oldItems, ...items]);
        e.reInit();
      }
    },
    [isNotCycle, items],
  );

  useEffect(() => {
    if (emblaApi) {
      //external lib error
      //@ts-ignore
      checkEnoughWidth(emblaApi);
    }
  }, [checkEnoughWidth, emblaApi]);

  useEffect(() => {
    changeSlide();
    if (emblaApi) {
      emblaApi?.on("select", changeSlide);
    }
    () => emblaApi?.off("select", changeSlide);
  }, [changeSlide, checkEnoughWidth, emblaApi, items]);

  useEffect(() => {
    if (!drag) {
      emblaApi?.reInit({ watchDrag: false });
    } else {
      emblaApi?.reInit({ watchDrag: true });
    }
  }, [drag, emblaApi]);

  return (
    <div
      className={cn(
        styles.gallery,
        styles[`theme-${theme}`],
        fullSize && styles["gallery--fullSize"],
        className,
      )}
    >
      <div
        ref={emblaRef}
        className={styles.gallery__wrapper}
        style={{
          maxWidth: !isImageZoomed ? undefined : isMobile ? "100%" : "calc(100% - 132px)",
        }}
      >
        <div
          ref={wrapperRef}
          className={cl(
            styles.gallery__container,
            items.length === 1 &&
              !lastSlideContent &&
              styles["gallery__container--center"],
            !isEnoughWidth &&
              !lastSlideContent &&
              !fullSize &&
              styles["gallery__container--small"],
          )}
        >
          {augmentedItems.map((item, i) => {
            const isActive = currentSlide === i;
            const lengthDiff = augmentedItems.length / items.length + 1;
            const currentNumber = i > items.length - 1 ? i - lengthDiff : i;
            return fullSize ? (
              <ResponsiveCard
                src={typeof item === "string" ? item : item.src}
                name={typeof item === "string" ? "" : item.name}
                fullSize
                index={i}
                key={typeof item === "string" ? item : item.src}
                onClick={() => onClick && onClick(i)}
                isActive={isActive}
                isMobile={isMobile}
                setActive={setDrag}
                setIsImageZoomed={setIsImageZoomed}
                isLocalImages
              />
            ) : (
              <div
                style={{ position: "relative" }}
                key={typeof item === "string" ? item : item.src}
              >
                <ResponsiveCard
                  src={typeof item === "string" ? item : item.src}
                  name={typeof item === "string" ? "" : item.name}
                  fullSize={fullSize}
                  index={i}
                  onClick={() => onClick && onClick(i)}
                  isActive={isActive}
                  isMobile={isMobile}
                  setIsImageZoomed={setIsImageZoomed}
                  isLocalImages
                />
                {!fullSize && isActive && (
                  <P1 className={styles.gallery__count}>
                    {String(currentNumber + 1).padStart(2, "0")}
                    <span>
                      /
                      {String(items.length + (lastSlideContent ? 1 : 0)).padStart(2, "0")}
                    </span>
                  </P1>
                )}
              </div>
            );
          })}
          {lastSlideContent}
        </div>
      </div>
      {items.length > 1 && (
        <div className={styles.gallery__buttons}>
          <CircleButton
            name="scroll to previous"
            as={"button"}
            content={<ArrowIcon />}
            onClick={scrollPrev}
            styleType={
              (isMobile && borderlessArrowButtonsMobile) || fullSize
                ? "secondary"
                : "accent"
            }
            theme={fullSize ? "dark" : "light"}
            className={cl(styles.gallery__button, styles["gallery__button--prev"])}
          />
          <CircleButton
            name="scroll to next"
            as={"button"}
            content={<ArrowIcon isRight />}
            onClick={scrollNext}
            styleType={
              (isMobile && borderlessArrowButtonsMobile) || fullSize
                ? "secondary"
                : "accent"
            }
            theme={fullSize ? "dark" : "light"}
            className={cl(styles.gallery__button, styles["gallery__button--next"])}
          />
        </div>
      )}
      {fullSize && items.length > 1 && (
        <P1 className={cl(styles.gallery__count, styles["gallery__count--fullsize"])}>
          {String(currentSlide + 1).padStart(2, "0")}
          <span>
            /{String(items.length + (lastSlideContent ? 1 : 0)).padStart(2, "0")}
          </span>
        </P1>
      )}
    </div>
  );
};

interface ICard {
  src: string;
  name?: string;
  fullSize?: boolean;
  index: number;
  isActive?: boolean;
  onClick?: (index: number) => void;
  isMobile: boolean;
  magnifierHeight?: number;
  magnifieWidth?: number;
  zoomLevel?: number;
  setActive?: (arg: boolean) => void;
  setIsImageZoomed?: Dispatch<SetStateAction<boolean>>;
  isLocalImages?: boolean;
}

const ResponsiveCard = ({
  src,
  fullSize,
  name,
  onClick,
  index,
  isActive,
  isMobile,
  setActive,
  setIsImageZoomed,
  isLocalImages
}: ICard) => {
  const transformerRef = useRef<null | ReactZoomPanPinchContentRef>(null);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [width, setWidth] = useState<string | number>(890);
  const height = isMobile ? 300 : 464;
  const calculatedWidth = isMobile ? 343 : width;
  const [isZoom, setIsZoom] = useState(false);
  const [[originalW, originalH], setIntrisicSize] = useState([2800, 2800]);
  const [[wrapperX, wrapperY], setWrapperSize] = useState([890, height]);
  const isVerticalImage = originalW > originalH;

  // const scale = isVerticalImage ? originalW / wrapperX : originalH / wrapperY;
  // const maxScale = scale > 2 ? 2 : scale;
  const maxScale = isVerticalImage ? originalW / wrapperX : originalH / wrapperY;

  const toggleZoom = useCallback(() => {
    setIsZoom((v) => !v);
  }, []);

  useEffect(() => {
    setActive && setActive(!isZoom);
    return () => {
      setActive && setActive(true);
    };
  }, [setActive, isZoom]);

  const handleZoomOut = useCallback(() => {
    transformerRef.current && transformerRef.current.zoomOut(maxScale);

    setIsZoom(() => false);
  }, [maxScale]);

  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    if (fullSize && wrapperRef.current) {
      const { width, height } = wrapperRef.current.getBoundingClientRect();
      if (isMobile) {
        setWrapperSize([window.innerWidth, height]);
      } else if (width) {
        setWrapperSize([width, height]);
      }
    }
  }, [fullSize, isMobile]);

  useEffect(() => {
    setIsImageZoomed && setIsImageZoomed(isZoom);
  }, [isZoom, setIsImageZoomed]);

  useEffect(() => {
    if (isZoom) {
      setTimeout(() => {
        transformerRef.current && transformerRef.current.centerView(maxScale);
      }, 0);
    }
  }, [isZoom, maxScale]);

  /* 702-(4) */
  useEffect(() => {
    if (isActive) {
      handleZoomOut();
    }
  }, [isActive, handleZoomOut]);

  const shouldZoom = maxScale > 1;
  const eventControl = useCallback((event: { type: string }) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragged(true);
    }
    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragged(false);
      }, 100);
    }
  }, []);

  return (
    <div
      className={cn(
        styles.gallery__slide,
        name && styles.gallery__slide_withName,
        isActive && styles["gallery__slide--active"],
      )}
      onClick={() => onClick && !fullSize && onClick(index)}
      style={{
        width: calculatedWidth,
        maxWidth: calculatedWidth,
      }}
    >
      {name && <P1 className={styles.gallery__slideName}>{name}</P1>}
      {fullSize ? (
        <div
          className={styles.gallery__slideWrapper}
          style={{
            cursor: !shouldZoom ? "default" : isZoom ? "zoom-out" : "zoom-in",
            width: isZoom ? "100%" : undefined,
          }}
          onClick={(e) => {
            if (isDragged) return;
            try {
              const evt = new MouseEvent("dblclick", {
                bubbles: true,
                cancelable: false,
              });
              e.currentTarget.children[0].dispatchEvent(evt);
            } catch (e) {
              console.log(e);
            }
          }}
          ref={wrapperRef}
        >
          <TransformWrapper
            maxScale={maxScale}
            onZoom={toggleZoom}
            centerZoomedOut
            centerOnInit
            doubleClick={{
              mode: isZoom ? "zoomOut" : "zoomIn",
            }}
            wheel={{
              disabled: true,
            }}
            ref={transformerRef}
            disablePadding
            onPanningStart={(_, e) => {
              eventControl(e);
            }}
            onPanning={(_, e) => {
              eventControl(e);
            }}
            onPanningStop={(_, e) => {
              eventControl(e);
            }}
            pinch={{ disabled: true }}
          >
            <TransformComponent
              wrapperClass={styles.transform__wrapper}
              wrapperStyle={{
                pointerEvents: isZoom ? "all" : "none",
                height: isZoom ? "100%" : undefined,
              }}
              contentClass={cn(styles.transform, !isZoom && styles.transform_zoom)}
            >
              <img
                onLoad={(e) => {
                  const elem = e.target as HTMLImageElement;
                  const { naturalHeight, naturalWidth } = elem;
                  setIntrisicSize(() => [naturalWidth, naturalHeight]);
                }}
                src={isLocalImages ? src : src + "-2800"}
                alt=""
                style={{
                  height: isZoom
                    ? undefined
                    : !isZoom && isMobile
                    ? "100%"
                    : wrapperRef.current?.getBoundingClientRect().height,
                  width: "auto",
                  aspectRatio: originalW / originalH,
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      ) : (
        <div onClick={() => onClick && onClick(index)}>
          <Image
            className={styles.gallery__card}
            src={src}
            alt=""
            key={src}
            sizes="(max-width: 768px) 343px, (max-width: 996px) 40vw,(max-width: 1440px) 90vw, 1000px"
            priority
            style={{ width: calculatedWidth, height: "auto", maxWidth: calculatedWidth }}
            onLoad={(e) => {
              const { naturalHeight, naturalWidth } = e.target as HTMLImageElement;
              const ratio = naturalWidth / naturalHeight;

              if (isMobile) setWidth("auto");
              else {
                setWidth(height * ratio);
              }
            }}
            height={height}
            width={isMobile ? 300 : 890}
          />
        </div>
      )}
    </div>
  );
};
