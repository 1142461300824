import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import cl from "classnames";
import { useWindowSize } from "@/lib/hooks/useWindowSize";
import { IClassname } from "@/lib/types/classname";
import cn from "classnames";
import { useRouter } from "next/router";
import { P1, P2 } from "../../Typography";
import { CircleButton } from "@/lib/ui/components/Buttons";
import { ArrowIcon } from "@/lib/ui/icons";
import { modal } from "@/lib/ui/components/Modal";

interface IGalleryModal extends IClassname {
  tabHeaders: string[];
  tabContents: React.ReactNode[];
  initialTabIndex?: number;
  leftHeaderContent?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
  footer?: React.ReactNode;
}

export const GalleryTabsModal: FC<IGalleryModal> = ({
  tabHeaders,
  tabContents,
  leftHeaderContent,
  rightHeaderContent,
  initialTabIndex,
  className,
  footer,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isTabAnimated = useRef(false);
  const [selected, setSelected] = React.useState(initialTabIndex);

  const onSelect = useCallback(
    (index: number) => {
      setSelected(index);
    },
    [setSelected],
  );
  return (
    <Tabs
      tabIndex={selected}
      defaultIndex={initialTabIndex}
      onSelect={onSelect}
      className={cn(styles.galleryModal, className)}
    >
      <div
        className={cn(styles.galleryModal__header, styles["galleryModal__header--tabs"])}
      >
        <CircleButton
          as="button"
          theme="mixed"
          onClick={() => modal.close()}
          name="return button"
          content={<ArrowIcon />}
          className={styles.backBtn}
        />
        {leftHeaderContent}
        {tabHeaders &&
          (!windowWidth || windowWidth >= 996) &&
          RenderTabList(tabHeaders, isTabAnimated, windowWidth, selected)}
        <div className={styles.galleryModal__headerActions}>{rightHeaderContent}</div>
      </div>
      <div className={styles.galleryModal__body}>
        {tabContents.map((tabContent, i) => (
          <TabPanel key={i} className={styles.tabPanel}>
            {tabContent}
          </TabPanel>
        ))}
      </div>
      {tabHeaders &&
        windowWidth &&
        windowWidth < 996 &&
        RenderTabList(tabHeaders, isTabAnimated, windowWidth, selected)}
      {footer}
    </Tabs>
  );
};

export const RenderTabList = (
  tabHeaders: string[],
  isTabAnimated: MutableRefObject<boolean>,
  windowWidth?: number,
  selected?: number,
  theme:
    | "light"
    | "dark"
    | "top-border"
    | "bottom-border"
    | "bottom-border-light" = "light",
  className?: string,
) => {
  const [offset, setOffset] = React.useState(0);
  const router = useRouter();
  const locale = router.locale;

  const [width, setWidth] = React.useState(0);
  const tabListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!tabListRef.current) return;
    const selectedItem: HTMLDivElement | null = tabListRef.current.querySelector(
      ".react-tabs__tab--selected",
    );

    if (selectedItem) {
      setOffset(selectedItem.offsetLeft);
      setWidth(selectedItem.clientWidth);
      setTimeout(() => {
        isTabAnimated.current = true;
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, windowWidth, tabListRef.current, tabHeaders, locale]);

  const isBorderTab = theme && theme.includes("border");
  return (
    <TabList
      className={cl(
        styles.tabs,
        styles.tab__list,
        theme === "dark" && styles["tab__list--dark"],
        theme === "top-border" && styles["tab__list--top-border"],
        theme === "bottom-border" && styles["tab__list--bottom-border"],
        theme === "bottom-border-light" && styles["tab__list--bottom-border-light"],
        className,
      )}
    >
      <div
        className={styles.tab__tracker}
        style={{
          width,
          transform: `translateX(${offset}px)`,
          transition: isTabAnimated.current ? ".25s all" : "none",
        }}
      />
      <div ref={tabListRef} style={{ display: "flex" }}>
        {tabHeaders.map((name) => (
          <Tab className={cl(styles.tab__button)} key={name}>
            {isBorderTab ? <P1>{name}</P1> : <P2>{name}</P2>}
          </Tab>
        ))}
      </div>
    </TabList>
  );
};
