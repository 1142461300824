import React, { FC, ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
import { Photo } from "@/lib/types/gallery";
import { IClassname } from "@/lib/types/classname";
import cn from "classnames";
import { VideoModal } from "@/lib/ui/components/VideoModal";

type Item = Partial<Photo> & { videoId?: string };

export interface GalleryGridProps extends IClassname {
  items: Item[];
  onItemClick?: (item: Item, index: number) => void;
  headerContent?: ReactElement;
  footerContent?: ReactElement;
}

export const GalleryGrid: FC<GalleryGridProps> = ({
  items,
  onItemClick,
  headerContent,
  footerContent,
  className,
}) => {
  const onClick = (item: Item, index: number) => () => {
    if (onItemClick) {
      onItemClick(item, index);
    }
  };

  return (
    <div className={cn(styles.grid, className)}>
      {items.map((item, index) =>
        item.src ? (
          <figure key={item.src} className={styles.photo} onClick={onClick(item, index)}>
            <Image 
        
 fill src={item.src} alt={item.name || ""} />
            {index === 0 && (
              <>
                <div className={styles.header}>{headerContent}</div>
                <div className={styles.footer}>{footerContent}</div>
              </>
            )}
          </figure>
        ) : (
          <VideoModal
            key={item.videoId}
            disablePlayOnClick
            className={styles.videoCover}
            videoId={item.videoId}
            onClick={onClick(item, index)}
          />
        ),
      )}
    </div>
  );
};
