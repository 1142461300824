import { FC, useState } from "react";
import styles from "./VideoModal.module.scss";
import cl from "classnames";
import Image from "next/image";
import { SvgPlayTriangle } from "../../icons";
import { P1 } from "../Typography";
import { SvgPlayTriangleOutlined } from "@/lib/ui/icons/SvgPlayTriangleOutlined";

interface IVideoModal {
  videoId?: string;
  className?: string;
  description?: string;
  onClick?: () => void;
  disablePlayOnClick?: boolean;
}
export const VideoModal: FC<IVideoModal> = ({
  videoId,
  className,
  description,
  onClick,
  disablePlayOnClick,
}) => {
  const [isActive, setIsActive] = useState(false);
  const initPlayback = () => {
    if (!disablePlayOnClick) {
      setIsActive(true);
    }
    if (onClick) {
      onClick();
    }
  };
  if (!videoId) return null;
  const videoUrl = "https://youtube.com/embed/" + videoId;
  return (
    <div
      className={cl(
        className,
        styles["video__overlay"],
        isActive && styles["video__overlay--active"],
      )}
      id={videoId}
      onClick={() => initPlayback()}
    >
      <Image 
        

        sizes="(max-width: 768px) 100vw, (max-width: 1320px) 100vw,(max-width: 1624px) 80vw, 1440px"
        className={cl(
          styles["video__thumbnail"],
          isActive && styles["video__thumbnail--inactive"],
        )}
        src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
        alt={"обложка"}
        fill
      />
      {!isActive && (
        <div className={styles.video__play}>
          <SvgPlayTriangleOutlined />
        </div>
      )}
      {isActive && (
        <iframe
          loading="lazy"
          className={cl(styles["video__iframe"], styles["video__iframe--active"])}
          src={videoUrl + "?autohide=1&showinfo=0" + (isActive ? "&autoplay=1" : "")}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      )}
      {description && <P1 className={styles.video__description}>{description}</P1>}
    </div>
  );
};
