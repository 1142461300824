import React, { FC } from "react";
import styles from "./styles.module.scss";
import { GallerySlider, IGallerySlider } from "@/lib/ui/components/Gallery";
import cn from "classnames";

interface IGalleryModal {
  photo: IGallerySlider["items"];
  headerActions?: React.ReactNode;
  initialSlide?: number;
  theme?: "default" | "light" | "transparent";
  isNotCycle? :boolean;
}

export const GalleryModal: FC<IGalleryModal> = ({
  photo,
  initialSlide = 0,
  headerActions,
  theme,
  isNotCycle
}) => {
  return (
    <div className={cn(styles.galleryModal, styles.noTabsView)}>
      {headerActions && (
        <div className={styles.galleryModal__header}>
          <div className={styles.galleryModal__headerActions}>{headerActions}</div>
        </div>
      )}
      <div className={styles.galleryModal__body}>
        <GallerySlider
          fullSize
          initialSlide={initialSlide}
          items={photo}
          theme={theme}
          className={styles.gallerySlider}
          isNotCycle={isNotCycle}
        />
      </div>
    </div>
  );
};
